body {
  font-family: "Graphik", sans-serif;
  background: $wallet-ui-background !important;
  padding: 10px;
  height: 100vh;
  overflow: hidden;
}

a {
  color: $pdax-green-color !important;
  text-decoration: none;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  min-height: 200px;
  height: 100%;
}

.step-bar {
  width: 100vw;
  height: 25px;
  background-color: #1F2552;
  position: relative;

  .step-bar-progress {
    position: absolute;
    height: 25px;
    top: 0;
    z-index: 0;
    background-color: #00CE89;
    transition: all ease-in-out 1s;
  }

  .progress-bar-steps {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    z-index: 1;
    font-size: 14px;

    .step-label {
      flex: 1 1 auto;
      padding: 0 5px;
      color: #888;
      transition: all ease-in-out 1s;
      text-align: center;

      &.active {
        color: #212529;
      }

      .step-title {
        //font-size: 12px
        transition: color 500ms ease-in-out;

        &.active {
          font-weight: 600;
        }

        &.current {
          color: #fff;
          font-weight: 600;
          //transition: color 1s ease-in-out;
        }
      }
    }
  }
}

.drop-zone {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed #D2D2D2;
  padding: 20px;
  border-radius: 6px;
  //transition: 500ms all ease-in-out;
  //padding: calc(var(--gutter)*2);
}

.rewards-portal {
  //background: $providers-background !important;
  padding-top: 10px;

  .rewards-column {
    position: relative;
    margin: 0px auto;
    //width: 410px;
    width: 450px;
    height: calc(100vh - 110px);
    //min-height: 650px;
    max-height: 100vh;
    border-radius: 16px;
    background-color: #ffffff;
    padding: 30px 16px;
    overflow: auto;
    box-shadow: 0px 5px 5px #00000014;

    img {
      display: block;
      margin-bottom: 16px;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain;
    }
    img.Star-Rewards {
      width: 124px;
      height: 61px;
    }

    img.icon-success {
      margin-top: 56px;
      display: block;
      width: 101px;
      height: 102px;
    }

    .redeem-rewards-title {
      font-family: Graphik;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.33;
      text-align: center;
      color: #1F2552;
    }

    .border-line {
      margin: 0px auto;
      width: 48px;
      height: 1px;
      border: solid 1px #57deb9;
    }

    .redeem-subtitle {
      font-family: Graphik;
      font-size: 14px;
      line-height: 1.29;
      margin-top: 30px;
      margin-bottom: 24px;
      text-align: center;
      color: #1F2552;
    }

    .referrals-subtitle {
      font-family: Graphik;
      font-size: 14px;
      line-height: 1.29;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
      color: #e4e4e4;
    }

    label {
      color: #1F2552;
    }

    label.code-label {
      display: block;
      padding: 8px 10px;
      margin: 0px auto;
      font-family: Graphik;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.02px;
      text-align: left;
    }

    label.radio-label {
      display: inline-block;
      padding: 8px 10px;
      margin: 0px auto;
      font-family: Graphik;
      font-size: 14px;
      line-height: 1.33;
      letter-spacing: 0.02px;
      text-align: left;
    }

    label.referral-code-label {
      display: block;
      padding: 8px 10px;
      margin: 0px auto;
      font-family: Graphik;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.02px;
      text-align: center;
      color: #9ca9ba;
    }

    input.input-code {
      display: block;
      margin: 0px auto;
      width: 362px;
      height: 48px;
      border-radius: 4px;
      border: solid 1px #537ad8;
      background-color: #171f33;
      padding: 16px;
      font-family: Graphik;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.21;
      text-align: center;
      color: #dee3eb;
    }

    .input-field {
      display: block;
      margin: 5px 10px 5px 10px;
      //width: 365px;
      width: 380px;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #E8E9ED;
      background: #F9FAFB;
      padding: 8px;
      font-family: Graphik;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.21;
      text-align: left;

      &.input-half-right {
        display: inline-block;
        margin-left: 5px;
        margin-right: 3px;
        width: 43.5%;
      }

      &.input-half-left{
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
        width: 45%;
      }

      &.input-third {
        display: inline-block;
        margin-left: 5px;
        margin-right: 10px;
        width: 68%;
      }

      &.input-quarter {
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
        width: 20%;
      }

      &.input-third {
        display: inline-block;
        margin-left: 5px;
        margin-right: 10px;
        width: 72%;
      }

      &.input-quarter {
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
        width: 20%;
      }

      &.input-third {
        display: inline-block;
        margin-left: 5px;
        margin-right: 10px;
        width: 72%;
      }

      &.input-quarter {
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
        width: 20%;
      }
    }

    input.input-radio {
      display: inline-block;
      height: 14px;
      color: #dee3eb;
      padding: 8px;
    }

    .cancel-button {
      //position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 10px;
      float: left;
      width: 160px;
      height: 48px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 32px;
      letter-spacing: normal;
      text-align: center;
      color: #00CE89;
      bottom: 33px;
      left: 24px;
      background: #ffffff;
      border: 0;
      cursor: pointer;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 30px;
    }

    .next-button {
      //position: absolute;
      //margin: 25px 10px;
      margin: 20px 10px;
      float: right;
      width: 160px;
      height: 48px;
      border-radius: 30px;
      background-color: #00CE89;
      border: 0;
      right: 24px;
      bottom: 24px;
      font-family: Graphik;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      transition: all ease-in-out 0.5s;
      cursor: pointer;
      &:disabled,
      &.disabled {
        background: #2e3b53;
        color: #62717e;
        cursor: not-allowed;
      }
    }

    .record-button {
      //position: absolute;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      border-radius: 30px;
      background-color: #00CE89;
      border: 0;
      right: 24px;
      bottom: 24px;
      font-family: Graphik;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      transition: all ease-in-out 0.5s;
      cursor: pointer;
      box-shadow: 0px 3px 6px #00000029;

      &:disabled, &.disabled {
        background: #2e3b53;
        color: #62717e;
        cursor: not-allowed;
      }
    }

    .back-to-button {
      width: 239px;
      height: 48px;
      margin: 0px auto;
      display: block;
      border-radius: 30px;
      border: 0;
      background-color: #53ffb0;
      font-family: Graphik;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.19;
      color: #1d2a43;
    }

    .reward-details-box {
      margin: 0 auto;
      margin-top: 40px;
      width: 100%;
      border-radius: 3px;
      background-color: #253046;
      padding: 24px;

      .reward-detail {
        font-family: Graphik;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: 0.02px;
        text-align: left;
        color: #9ca9ba;

        .title,
        .description {
          display: inline-block;
          width: 50%;
        }

        .description {
          font-weight: normal;
        }
      }
    }
  }
}

.psp-container {
  padding: 10px;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.divider {
  height: 2px;
  background: #1d2538;
  margin: 20px 0px;
}

.pdax-modal {
  box-shadow: 0px 5px 5px #00000014;
  border-radius: 16px;
  background-color: #ffffff !important;

  &.animated {
    animation-duration: 300ms;
  }

  .swal2-title, .swal2-header, .swal2-content, .swal2-footer {
    color: #1F2552 !important;
  }

  button {
    outline: 0;
    color: #253046 !important;
  }
}

.full-height-container {
  height: 100% !important;
  overflow-x: hidden !important;
}

.no-margin {
  margin: 0 !important;
}

.no-left-margin {
  margin-left: 0 !important;
}

.no-right-margin {
  margin-left: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.intent-popup {
  width: 100vw;
  height: calc(100vh - 64px);
  top: 64px;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 2;

  .intent-content {
    margin: 100px auto;
    height: 374px;
    width: 550px;
    padding-top: 57px;
    padding-left: 24px;
    padding-bottom: 37px;
    background: #F9FAFB;
    vertical-align: top;
    border-radius: 10px;

    .intent-button {
      display: inline-flex;
      flex-direction: column;
      vertical-align: top;
      width: 239px;
      height: 280px;
      border-radius: 10px;
      background-color: #ffffff;
      border: 1px solid #E8E9ED;
      font-family: Graphik;
      font-size: 19px;
      font-weight: 500;
      font-style: normal;
      //line-height: 1.21;
      text-align: center;
      color: #1d2a43;
      transition: all ease-in-out 0.5s;
      padding: 24px 12px;
      cursor: pointer;
      box-shadow: 0px 5px 5px #00000014;

      .intent-img-1 {
        margin-top: 27px;
        width: 143px;
        height: 96px;
      }
      .intent-img-2 {
        margin-top: 27px;
        width: 192px;
        height: 96px;
      }

      .intent-text {
        width: 241px;
        height: 28px;
        font-size: 19px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.26;
        letter-spacing: normal;
        text-align: center;
        color: #1F2552;

        &.intent-text-2 {
          //line-height: 90px;
        }
      }

      .intent-details {
        margin-top: 10px;
        font-size: 12px;
        color: #1F2552;
        line-height: 14px;
      }

      &:first-of-type {
        margin-right: 22px;
      }
      &:disabled,
      &.disabled {
        background: #2e3b53;
        color: #62717e;
        cursor: not-allowed;
      }
    }
  }
}

.share-buttons {
  text-align: center;
  align-items: flex-start;

  a {
    width: 40px;
    height: 40px;
    margin: 8px;
    display: inline-block;
    cursor: pointer;

    img {
      display: inline-block !important;
      width: 40px;
      height: 40px;
    }
  }
}

.uio-content-caution {
  //SECTION: AZIZ
  display: table;
  width: 100%;
  padding: 10px 10px 5px 10px;
  border-radius: 5px;
  margin-bottom: 25px;
  border: 1px solid;
  box-sizing: border-box;
  font-size: 12px;
  position: relative;
  overflow: hidden;

  //
  //&::before {
  //
  //  content: "";
  //  display: table;
  //  width: 50px;
  //  height: 50px;
  //  position: absolute;
  //  left: 0;
  //  margin-top: -25px;
  //  top: 50%;
  //  line-height: 120px;
  //  z-index: 2;
  //}

  &::after {
    content: "";
    display: flex;
    width: 50px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  ul {
    padding-inline-start: 25px;
  }
}

.uio-content-caution.uio-time {
  color: #0c5460;
  border-color: #bee5eb;
  background-color: #d1ecf1;

  &::before {
    background-color: #0c5460;
    mask: url(/iconclock.svg) no-repeat 50% 50%;
    -webkit-mask: url(/iconclock.svg) no-repeat 50% 50%;
    mask-size: 50%;
    -webkit-mask-size: 50%;
  }
}

@media (max-width: 580px) {
  .rewards-column {
    width: 100% !important;

    .input-field {
      width: 100% !important;

      &.input-half {
        margin: 5px 0px !important;
      }
    }
  }

  .intent-popup {
    //height: 100vh !important;
    top: 64px !important;
    background: #19243c !important;
    ovetflow: auto;

    .intent-content {
      margin: 0 !important;
      width: 100vw !important;
      height: 100vh !important;
      padding: 0px !important;

      .intent-button {
        width: 70vw !important;
        margin: 10px auto !important;
        display: block !important;
      }
    }
  }

  .step-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .step-title {
      font-size: 10px;
      //display: none;
    }
  }
}

.text-red {
  color: orangered;
}

.btn-document {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #00CE89 !important;
  background-color: #ffffff !important;
  display: block !important;
  margin: 20px auto !important;
  border-radius: 30px !important;
  min-width: 164px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.7rem 1rem !important;

  &:hover,
  &:focus {
    background-color: $default-button-hover-color !important;
    color: #ffffff !important;
  }
  &:active {
    background-color: $default-button-active-color !important;
    color: #ffffff !important;
  }
}

.webcam-container {
  margin: 0px auto;
  border-radius: 50%;
  overflow: hidden;
  width: 192px;
  height: 192px;
  position: relative;

  .counter-container {
    background: rgba(0, 0, 0, 0.8);
    font-size: 64px;
    text-align: center;
    width: 192px;
    height: 192px;
    line-height: 192px;
    color: #fff;
    position: absolute;
    z-index: 1;
  }

  button {
    position: absolute;
    left: 50px;
    z-index: 10;
    font-size: 12px;
  }
}

.UnsupportedBrowsers_item-wrapper__Xa3Sk {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 16px;

  .UnsupportedBrowsers_group-left__3kbTu {
    width: 70%;
  }

  .UnsupportedBrowsers_group-left__3kbTu div:not(:last-of-type) {
    width: 40%;
  }

  .UnsupportedBrowsers_group-left__3kbTu div:not(:last-of-type),
  .UnsupportedBrowsers_group-right__1F4x0 div:not(:last-of-type) {
    display: inline-block;
  }

  .UnsupportedBrowsers_item-wrapper__Xa3Sk a {
    text-decoration: none;
    color: #fff;
  }

  .UnsupportedBrowsers_group-left__3kbTu .UnsupportedBrowsers_platform__18kys,
  .UnsupportedBrowsers_group-right__1F4x0 .UnsupportedBrowsers_platform__18kys {
    margin-top: 5px;
    color: #fff;
    text-align: center;
  }

  @media (max-width: 568px) {
    .UnsupportedBrowsers_group-right__1F4x0 {
      width: 35%;
    }
  }

  .UnsupportedBrowsers_group-right__1F4x0 {
    width: 30%;
  }

  .UnsupportedBrowsers_group-left__3kbTu div:first-of-type a p {
    font-size: 14px;
    text-align: center;
    border-left: 1px solid #aaa;
    padding-left: 10px;
    padding-right: 10px;
  }

  .UnsupportedBrowsers_group-left__3kbTu div:nth-of-type(2) a p {
    font-size: 14px;
    text-align: center;
    border-right: 1px solid #aaa;
    padding-left: 10px;
    padding-right: 10px;
  }

  .UnsupportedBrowsers_group-left__3kbTu div:first-of-type a p,
  .UnsupportedBrowsers_group-left__3kbTu div:nth-of-type(2) a p,
  .UnsupportedBrowsers_group-right__1F4x0 div a p {
    padding-bottom: 15px;
    border-bottom: 1px solid #aaa;
    color: #fff;
    text-decoration: none;
  }

  .UnsupportedBrowsers_item-wrapper__Xa3Sk p {
    margin-top: 15px;
    font-size: 14px;
  }

  .UnsupportedBrowsers_group-right__1F4x0 div a p {
    font-size: 14px;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    border-right: 1px solid #aaa;
    border-left: 1px solid #aaa;
    text-decoration: none;
  }
}

.capitalize {
  text-transform: capitalize;
}
