@import '../vendor/fontawesome/fontawesome';
@import '../vendor/fontawesome/fa-solid';
@import '../vendor/fontawesome/fa-regular';
@import '../vendor/fontawesome/fa-light';
@import '../vendor/fontawesome/fa-brands';
@import '../vendor/animate';
@import '../mixins';

// Fonts
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Extralight-Web.woff');
  font-weight: $extralight;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Light-Web.woff');
  font-weight: $light;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Regular-Web.woff');
  font-weight: $regular;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Medium-Web.woff');
  font-weight: $medium;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Semibold-Web.woff');
  font-weight: $semibold;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('/webfonts/graphik/Graphik-Bold-Web.woff');
  font-weight: $bold;
  font-style: normal;
}
@font-face {
  font-family: 'RobotoMono';
  src: url('/webfonts/Roboto_Mono/RobotoMono-Light.ttf');
  font-weight: $light;
  font-style: normal;
}
@font-face {
  font-family: 'RobotoMono';
  src: url('/webfonts/Roboto_Mono/RobotoMono-Regular.ttf');
  font-weight: $regular;
  font-style: normal;
}
@font-face {
  font-family: 'RobotoMono';
  src: url('/webfonts/Roboto_Mono/RobotoMono-Medium.ttf');
  font-weight: $medium;
  font-style: normal;
}
@font-face {
  font-family: 'RobotoMono';
  src: url('/webfonts/Roboto_Mono/RobotoMono-Bold.ttf');
  font-weight: $bold;
  font-style: normal;
}


$font-family: 'Graphik', sans-serif;
$font-awesome: 'Font Awesome 5 Pro', sans-serif;

// Globally-Used Color Palette
// Numbered colors should go in order of brightness
$white-color-1: #f2f2f2;
$white-color-2: #e4e4e4;
$white-color-3: #c7c7c7;
$white-color-4: #bcbcbc;
$light-color-1: #dee3eb; // for non-shade-of-white light colors
$light-color-2: #c9d0db; // if the hex repeats 3 times, it should be a $white-color-#
$light-color-3: #abb3be;
$medium-color-1: #697e90;
$medium-color-2: #53697c;
$medium-color-3: #36435b; // instrument select background
$medium-color-3b: #2e3b53; // instrument select divider lines
$medium-color-4: #27375c;
$medium-color-4b: #253046;
$medium-color-5: #40516f;
$medium-color-5: #1d2538;
$dark-color-1: #1d2a43;
$dark-color-2: #1b2233; // used in dropdown 
$dark-color-3: #19243c; // general component background
$dark-color-4: #F9FAFB; // general background color
$dark-color-4b: #131f30; // marketing lower bg color
$dark-color-5: #101624; // used in header
$dark-color-6: #171f33; // used in KYC Tiers landing, presentation block - tier items' border color
$dark-color-7: #1d2538; // background for trade-ui cards
$dark-color-8: #253046;
$pdax-green-color: #37f09b; // pdax-green
$pdax-green-alt-color: #37f0cc;
$pdax-blue-color: #537ad8;
$pdax-blue-color-2: #6a92f4; // used in KYC Tiers landing, presentation block - limits icons color
$pdax-blue-alt-color: #82a3f2;
$pdax-red-color: #ba495a;
$shift-green-color: #7acd45; // shift-green
$bid-color-brighter: #57deb9;
$bid-color: #17a085;
$bid-color-subdued: #234d45;
$ask-color-brighter: #de5f73;
$ask-color: $pdax-red-color;
$ask-color-subdued: #6a3644;
$default-button-color: #53ffb0;
$default-button-hover-color: #57deb9;
$default-button-active-color: #17a085;
$default-font-color-brighter: #c7d5ea;
$default-font-color: #9ca9ba;
$default-font-color-subdued: #62717e;
$error-color: $pdax-red-color;

// Common Element Styles
$highlight-color: rgba(white, 0.25);
$active-font-color: $bid-color-brighter;
$hover-font-color: $light-color-1;

// BUTTON COLORS
$button-border-color: $default-button-color;
$button-bg-color: $default-button-color;
$button-font-color: $medium-color-4;
$button-hover-bg-color: $default-button-hover-color;
$button-hover-font-color: $dark-color-4;
$button-hover-border-color: $default-button-hover-color;
$button-disabled-bg-color: $medium-color-3b;
$button-disabled-border-color: $medium-color-3b;
$button-disabled-font-color: $default-font-color-subdued;
$button-focus-color: $default-button-color;

// SIMPLE UI BUTTONS
$button-simple-ui-bg-color: $default-button-color;
$button-simple-ui-color: $dark-color-1;

// SCROLLBAR
$scrollbar-width: 8px;
$scrollbar-height: 8px;
$scrollbar-thumb-color: $medium-color-2;
$scrollbar-bg-color: $dark-color-5;

// FORM CONTROL COLORS
$form-field-border-color: $white-color-3;
$form-field-border-color-active: $dark-color-2;
$form-field-border-color-error: $pdax-red-color;
$form-field-input-bg-color: $light-color-1;
$form-field-text-color: $dark-color-3;

$alternate-row-color: rgba($medium-color-4, 0.75);

$table-border-color: $light-color-2;

$input-bg-color: $dark-color-1;
$input-font-color: $light-color-1;
$input-placeholder-color: $default-font-color;
$input-border-color: $default-font-color-subdued;
$input-focus-color: $pdax-blue-color;
$input-focus-background: $dark-color-6;
$input-disabled-bg-color: $medium-color-3b;
$input-disabled-placeholder-color: $dark-color-1;
$input-disabled-border-color: transparent;

$select-focus-color: $light-color-1;

$component-bg-color: $dark-color-3;
$component-header-bg-color: $dark-color-3;
$component-header-tab-divider-color: $white-color-3;
$component-header-active-font-color: $default-font-color-subdued;
$component-header-inactive-font-color: $default-font-color-subdued;

$responsive-menu-bg-color: $dark-color-2;
$responsive-menu-outline-color: $light-color-2;
$responsive-menu-hover-font-color: $hover-font-color;

$modal-bg-color: $component-bg-color;

$paginator-active-font-color: $active-font-color;

// Datepicker colors
$datepicker-background-top-color: $shift-green-color;

// BUY SELL MODAL
$buy-sell-modal-color: $dark-color-3;

// Maintenance Mode
$maintenance-mode-content: $light-color-1;

// KYC Tiers

$tiers-font-family: 'Graphik', sans-serif;
$tiers-cancel-link-color: #57deb9;
$tiers-presentation-submitted-title-color: #23292e;
$tiers-top-message-border-color: #0e6f5c;
$tiers-top-message-text-color: $bid-color-brighter;

$tiers-container-padding-top: 15px;
$tiers-container-padding-bottom: 23px;
$tiers-container-vertical-padding: $tiers-container-padding-top + $tiers-container-padding-bottom;
$tiers-top-message-margin-bottom: $tiers-container-padding-top;
$trade-ui-header-height: 40px;

// Settings page

$settings-container-min-height: calc(100vh - #{$trade-ui-header-height} - #{$tiers-container-vertical-padding});
$settings-nav-active-color: $medium-color-3;
$settings-nav-font-color: $light-color-2;
$settings-link-color: #57deb9;

$warning-color: #f8fe54;
