
.pdax-header {
  font-size: .9rem;
  width: 100%;
  background: #F9FAFB;
  padding: 0 !important;
  position: relative;
  height: 64px;
  letter-spacing: .0625em;
  color: #94ADBE !important;

  .navbar-brand {

    width: 100%;
    height: 64px;
    //padding: 0 !important;
    //position: absolute;
    display: block;
    text-align: center;

    img {
      //display: block;
      width: 150px;
      height: 58px;
      -o-object-fit: contain;
      object-fit: contain;
      -o-object-position: 0 50%;
      object-position: 0 50%;
      //font-family: 'object-fit: contain; object-position: 0 50%';
    }

  }

  a {
    color: #94ADBE !important;

    &:hover, &:active, &:focus {
      color: #53ffb1 !important;
    }
  }

}

header.main a {
  color: #94ADBE
}

header.main a:focus,header.main a:hover {
  color: rgba(148,173,190,.8)
}

header.main hr {
  border-top: 1px solid #404550;
  margin: 2.5px 5px
}

header.main nav>ul>div>li,header.main nav>ul>li {
  padding: 0;
  display: inline-block
}

header.main button {
  padding: 10px!important
}

header.main nav li.dropdown.open {
  background-color: #000001
}

header.main nav .dropdown-menu {
  margin-top: -1px;
  border-top: none
}

header.main .top-logo {
  width: 150px;
  height: 58px;
  position: absolute
}

header.main .top-logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0 50%;
  object-position: 0 50%;
  font-family: 'object-fit: contain; object-position: 0 50%'
}

@media (max-width: 992px) {
  header.main .top-logo {
    width:112.5px
  }

  header.main a {
    font-size: .675rem
  }
}

header.main .btn.menu-toggle,header.main .btn.menu-toggle-right .icon,header.main .btn.menu-toggle-right .icon:focus,header.main .btn.menu-toggle:focus {
  font-size: 24px
}

header.main select,header.main select option {
  background-color: #ccc;
  border: none;
  border-radius: 0;
  padding: 0 30px 0 10px;
  color: #fff
}

header.main button#trade-as {
  border: none;
  width: 100%;
  padding: 0!important;
  background-color: #fff;
  color: #fff
}

header.main .btn.menu-toggle {
  text-align: center;
  float: left;
  padding: 0 10px;
  color: #94ADBE;
  margin: 0 5px
}

header.main .btn.menu-toggle:hover {
  color: rgba(148,173,190,.5)
}

header.main .btn.menu-toggle i {
  margin: 0
}

header.main .btn.menu-toggle-right {
  position: relative;
  text-align: center;
  float: right;
  min-width: 39px;
  height: 58px;
  padding: 0 10px;
  color: #94ADBE;
  margin: 0 5px
}

header.main .btn.menu-toggle-right .svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 20px;
  height: 20px
}

header.main .btn.menu-toggle-right .svg * {
  fill: #94ADBE
}

header.main .btn.menu-toggle-right .text {
  height: 58px
}

header.main .btn.menu-toggle-right:hover {
  color: rgba(148,173,190,.5)
}

header.main .btn.menu-toggle-right i {
  margin: 0
}

header.main nav {
  display: block;
  float: right;
  line-height: 37px
}

header.main nav i {
  margin-right: 5px
}

header.main nav li:not(.side-menu-button-li) {
  display: inline-block;
  list-style: none;
  line-height: 37px
}

header.main nav li:not(.side-menu-button-li) a {
  display: block;
  padding: 10px;
  line-height: inherit
}

header.main nav li:not(.side-menu-button-li) ul.dropdown-menu {
  max-height: 500px;
  padding-top: 2px;
  padding-bottom: 0
}

header.main nav li:not(.side-menu-button-li) ul.dropdown-menu li {
  display: block;
  line-height: unset!important;
  padding: 0
}

header.main nav li:not(.side-menu-button-li) ul.dropdown-menu li a {
  padding: 0 20px
}

header.main nav li.side-menu-button-li {
  padding: 10px
}

@media (max-width: 992px) {
  header.main nav li.side-menu-button-li {
    padding-left:5px;
    padding-right: 5px
  }
}

header.homeMain {
  background: rgba(16,22,36,0)
}
